.list-items:hover{
    background-color: rgb(225, 225, 225);
}

.card-group{
    /* height: 300px; */
    display: flex;
    flex-direction: column;
}

.betting-guide{
    text-align: center;
    margin: 0 100px;
    cursor: pointer;
}

.betting-guide .betting-heading{
    color:red !important;
}
.sale{
    margin: 0;
}

.betting-guide-heading{
    margin-top: 270px;
    /* border: 1px solid red; */
}
.guides-row{
    margin:0;
}
.card-group .card-element{
    display: flex;
    padding: 1em;
    margin: 1em;

    /* border: 1px solid black;
    borf */
}
.card-group .card-element .card-media{
    width: 200px;
    background-position:center;
}
.card-group .card-element .text-content{
    font-size: 20px;
    /* color: red; */
}

.flag-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
    width: 50vh;
    background-color: #d2f3f9;
    cursor: pointer;
}

.flag-image {
    width: 45%;
    height: auto;
}

.vs-text {
    position: absolute;
    font-size: 2rem;
    font-weight: bold;
    color: transparent;
    transition: color 0.3s ease;
}

.flag-container .vs-text {
    color: red;
    background: #f0f8ff75;
    border: 2px solid;
    border-radius: 50%;
}
