.post-container {
  background: #e2e8d5;
  display: flex;
  flex-direction: column;
  padding: 3%;
  margin: 0 2%;
}

.content-container :hover {
  cursor: pointer;
}

.image {
  width: 25%;
  height: 210px;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

.header {
  background-color: #1a73e8;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header nav a {
  color: white;
  margin: 0 15px;
  text-decoration: none;
}

.auth-links a {
  color: white;
}

.hero {
  background-color: #e3f2fd;
  padding: 50px 20px;
  text-align: center;
}

.hero h1 {
  margin-bottom: 20px;
}

.hero button {
  padding: 10px 20px;
  background-color: #1a73e8;
  color: white;
  border: none;
  cursor: pointer;
}

.predictions {
  display: flex;
  justify-content: space-around;
  margin: 20px;
}

.prediction-card {
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 30%;
}

.live-score-ticker {
  background-color: #1a73e8;
  color: white;
  padding: 10px;
  text-align: center;
  margin: 20px 0;
}

.footer {
  background-color: #1a73e8;
  color: white;
  padding: 20px;
  text-align: center;
}

.footer a {
  color: white;
  text-decoration: none;
}

.blog-container {
  position: relative;
}

.hover-button {
  position: fixed;
  bottom: 20px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000; /* Ensure it's on top of other elements */
}

.futuristic-button {
  display: flex;
  align-items: center;
  background-color: rgba(244, 67, 54, 0.5); /* Red with 80% opacity */
  color: white;
  border: none;
  border-radius: 30px; /* Curved sides */
  padding: 10px 20px; /* Adjust size as needed */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s, transform 0.3s;
  cursor: pointer;
  font-size: 16px; /* Adjust font size as needed */
}

.futuristic-button:hover {
  background-color: rgba(198, 40, 40, 0.9); /* Darker red with 90% opacity */
  transform: scale(1.05);
}

.button-icon {
  font-size: 24px; /* Adjust icon size as needed */
  margin-right: 8px; /* Space between icon and text */
}

.button-text {
  font-size: 16px; /* Adjust text size as needed */
}
