    .matches-container {
        padding: 20px;
    }

    .matches-header {
        display: flex;
        justify-content: center;
    }

    .card-container {
        display: flex;
        justify-content: center;
    }

    .match-card {
        max-width: 800px;
        width: 100%;
        margin: 20px;
        position: relative;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    .card-header {
        text-align: center;
        background-color: #f5f5f5;
    }

    .flag-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        width: 100%;
    }

    .team-flag {
        width: 80px;
        height: 50px;
        margin: 0 20px;
    }

    .vs-text {
        font-size: 24px;
        font-weight: bold;
    }

    .match-content,
    .match-tags {
        text-align: center;
    }

    .session-options-container {
        display: flex;
        justify-content: space-around;
        padding: 20px;
    }

    .team-options {
        display: flex;
        flex-direction: column;
    }

    .session {
        margin-bottom: 15px;
    }

    .session-button {
        margin-right: 10px;
    }

    .Winner,
    .Loser {
        margin-top: 20px;
        padding: 15px;
        border-radius: 8px;
        transition: background-color 0.3s ease, transform 0.3s ease;
        width: 100%;
    }

    .Winner {
        background-color: rgb(71, 238, 71);
        box-shadow: 0 4px 8px rgba(44, 241, 63, 0.822);

    }

    .Loser {
        background-color: rgb(238, 124, 124);
        box-shadow: 0 4px 8px rgba(218, 40, 40, 0.822);

    }

    .Winner:hover,
    .Loser:hover {
        background-color: rgb(163, 219, 241);
        transform: scale(1.05);
    }


    .winner-button {
        width: 100px;
    }

    .vs-separator {
        width: 10%;
        text-align: center;
        align-self: center;
        font-size: 32px;
        font-weight: bold;
    }

    .submit-button-container {
        text-align: center;
        padding: 20px;
    }

    @media (max-width: 768px) {

        .vs-separator {
            display: none;
        }

        .team-options:nth-child(1) {
            border-right: 1px solid;
            padding-right: 10px;
        }
        .team-options{
            padding-left: 10px;
        }
        p {
            margin: 2px;
            margin-bottom: 1rem;
        }
    }